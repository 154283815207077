
// function About(){
// 	return (
// 		<div className="container mx-auto py-6">
// 			<div className="flex flex-row flex-wrap justify-evenly">
// 				<div className="flex-col bg-green-800 text-white lg:w-1/2 p-6 rounded-xl">
// 					<div className="text-4xl font-bold text-center">
// 					About
// 					</div>
// 					<div className="">
// 						<div className="text-yellow-400 leading-7">[This text needs to be updated]</div> Kittilsen’s Honey Ltd. is a commercial apiary operation located in Central Nova Scotia, Canada managing 1600 + honeybee colonies. We supply quality colonies to wild blueberry producers for pollination and produce wildflower honey which is extracted and packaged in our federally registered packing facility. As an apiary assistant with Kittilsen’s Honey Ltd. you will work closely with our beekeepers, to manage colonies of honeybees for the production of honey as well as pollination services.
// 					</div>
// 				</div>
// 				<div className="flex flex-col justify-evenly">
// 					<img className="w-96 rounded-xl outline outline-black outline-4" src="family_photo.jpeg" alt="Family"/>
// 				</div>
// 			</div>
// 		</div>

// 	);
// }

export default function About() {
  return (
    <div className="overflow-hidden bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About</h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
				Some text about kittilsens Wildflower Honey
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
              fugiat veniam occaecat fugiat. Quasi aperiam sit non sit neque reprehenderit.
            </p>
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
				src="family_photo.jpeg"
                alt=""
                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover outline outline-8 outline-yellow-300"
              />
            </div>
            <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
              <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                <img
                  src="20210518_171759.jpg"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover outline outline-8 outline-green-900"
                />
              </div>
              <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                <img
                  src="20220521_143551.jpg"
                  alt=""
                  className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover outline outline-8 outline-yellow-300"
                />
              </div>
              <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                <img
                  src="20200425_155106.jpg"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover outline outline-8 outline-green-900"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
