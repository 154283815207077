const products = [
  {
    id: 1,
    name: 'Liquid Honey',
    price: '$7',
    description: 'Wildflower Liquid Honey description',
    options: '3 Sizes',
    imageSrc: 'honey.jpg',
    imageAlt: 'Liquid Honey'
  },
  {
    id: 2,
    name: 'Creamed Honey',
    price: '$10',
    description: 'Creamed Honey description',
    options: '250ml, 1L',
    imageSrc: 'honey.jpg',
    imageAlt: 'Creamed Honey',
  },
  {
    id: 2,
    name: 'Candles',
    price: '$10',
    description: 'candles description',
    options: '250ml, 1L',
    imageSrc: 'honey.jpg',
    imageAlt: 'Candles No Image Yet',
  },
  // More products...
]

export default function Products() {
  return (
      <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
		<h2 className="text-3xl font-bold tracking-tight text-gray-900">Products</h2>

        <div className="mt-6 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {products.map((product) => (
            <div
              key={product.id}
              className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
            >
              <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none sm:h-96">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                />
              </div>
              <div className="flex flex-1 flex-col space-y-2 p-4">
                <h3 className="text-sm font-medium text-gray-900">
                  <div className="">
                    <span aria-hidden="true" className="absolute inset-0" />
                    {product.name}
                  </div>
                </h3>
                <p className="text-sm text-gray-500">{product.description}</p>
                <div className="flex flex-1 flex-col justify-end">
                  <p className="text-sm italic text-gray-500">{product.options}</p>
                  <p className="text-sm font-medium text-gray-900">Starting at {product.price}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>)
}
