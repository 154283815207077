
const navigation = [
  { name: 'Home', href: '/' },
  { name: 'About', href: '/' },
  { name: 'Products', href: '/' },
  { name: 'Location', href: '/' },
  // { name: 'Meet the Team', href: '#' },
  { name: 'Contact Us', href:'/' }
]

export default function Navbar(){
	return (
		<header className="bg-yellow-300 sticky top-0 z-10">
			<div className="container mx-auto">
			<nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
			  <div className="flex lg:flex-1">
				<a href="/" className="-m-1.5 p-1.5">
				  <span className="sr-only">Kittilsen's Honey</span>
				  <img
					className="h-8 w-auto"
					src="klogo.svg"
					alt=""
				  />
				</a>
			  </div>
			  <div className="flex lg:hidden">
				<button
				  type="button"
				  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
				  onClick={() => true}
				>
				  <span className="sr-only">Open main menu</span>
				</button>
			  </div>
			  <div className="hidden lg:flex lg:gap-x-12">
				{navigation.map((item) => (
				  <a key={item.name} href={item.href} className="text-sm font-semibold text-gray-900">
					{item.name}
				  </a>
				))}
			  </div>
			</nav>
			</div>
		</header>
	)
}
