import About from './components/about';
import Email from './components/emails';
import Faqs from './components/faqs';
import Footer from './components/footer';
import Locations from './components/locations';
import Main from './components/main';
import Navbar from './components/navbar';
import Products from './components/products';
import Staff from './components/staff';

function App() {
  return (
    <div className="">
		<Navbar />

		<Main />

		<About />

		<Products />

		<Locations />

		<Staff />

		<Faqs />

		<Email />

		<Footer />

    </div>
  );
}

export default App;
