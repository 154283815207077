// import StaffCard from "./staff_card";

const people = [
  {
    name: 'John Stiles',
    role: '26 Years',
    imageUrl: 'blank_profile.png',
    bio: 'Born and raised in Debert Nova Scotia, our head beekeeper John has always had an interest in agriculture. Graduating from the Nova Scotia Agricultural College with a diploma in agricultural engineering made him the perfect fit as Kittilsen’s Honey first hired beekeeper, back in 1997. A real ‘John of all trades’, he is skilled in all things woodworking, electrical, mechanical and plumbing, making him a huge asset on the farm. Over the years he has helped build buildings, extraction facilities, develop and refine gear that suits our needs on top of quality seasonal care of our bees. John’s favorite time in the bee season is spring and summer splits, as the bees are strong and healthy, making it a very productive time of year. In his spare time, John can be found developing his blueberry fields, working in his vegetable garden, watching football or listening to some great ‘80’s music.',
  },
  {
    name: 'Lilly Burgoyne',
    role: '10 Years',
    imageUrl: 'blank_profile.png',
    bio: 'Born and raised in Lunenburg County Nova Scotia, Lilly was always wanting to learn more about agriculture and the great outdoors. After graduating with a degree in animal sciences from Dalhousie Agricultural Campus, she thought a season as a beekeeper sounded interesting, after seeing a job posting for Kittilsen’s in 2017. It didn’t take long for her to realize beekeeping was the job for her! She followed her first season at Kittilsen’s with a trip to New Zealand where she worked for a large operstion, raising queens and seeing how bee things were done down under. Lilly’s favorite time of the year is queen rearing season, where she can always hone her skills and manage a timely system to produce local queen bees. When she is not working in the bees, Lilly can be found working in her vegetable garden, spending time with her chickens and rabbits, or out for a long horseback ride.'
  },
  {
    name: 'Nabross Thik',
    role: '6 Months',
    imageUrl: 'blank_profile.png',
    bio: 'Nabross was born and raised in Siem Reap, Cambodia. He studied to be a pastry chef at the Paul Dubrule School of Hospitality, then completed a major in English literature at the National University of Management, Phnom Penh. Nabross started working for Kittilsen’s Honey in early 2023, preparing and building frames, boxes, and lids for the upcoming bee season. Relocating to Canada has been a huge adjustment that he takes in stride every day, no matter the temperature, weather or task. He hopes for his family to eventually move to Canada to join him. Nabross’ favorite part of beekeeping so far, is opening a strong and healthy hive that has made it successfully through Canada’s cold winter. In his spare time Nabross enjoys watching movies, making youtube videos of his adventures, exercising outdoors and cooking.'
  },
  {
    name: 'Pisith Ratha',
    role: '6 Months',
    imageUrl: 'blank_profile.png',
    bio: 'Pisith was born and raised in Banteaymeacheny Cambodia, which is close to the Thailand border. He studied English at Western University  and then carried on to complete a law degree at the Royal University of Law and Economics in Phnom Penh. Pisith enjoys experiencing different cultures and felt that seeking work in Canada would be the ultimate adventure and learning experience.He started at Kittilsen’s Honey in early 2023 building and preparing frames, boxes and other equipment for the fast approaching bee season. Pisith is very excited to participate in our bee moving, when we take our hives to wild blueberry fields to pollinate the crops. The late nights, logistics and remote locations are truly quite an experience. In his spare time Pisith can be found enjoying a good book, watching travel videos, studying science and history, or having a good laugh.'
  },
]

export default function Staff() {
  return (
    <div className="bg-white py-24 md:py-32">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-5">
        <div className="max-w-2xl xl:col-span-2">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet the team</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
			Meet the beekeepers that make it happen
          </p>
        </div>
        <ul className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
          {people.map((person) => (
            <li key={person.name} className="flex flex-col gap-10 pt-12 sm:flex-row">
              <img className="aspect-[4/5] h-52 w-52 flex-none rounded-2xl object-cover" src={person.imageUrl} alt="" />
              <div className="max-w-xl flex-auto">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-base leading-7 text-gray-600">{person.role}</p>
                <p className="mt-6 text-base leading-7 text-gray-600">{person.bio}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
                // <ul role="list" className="mt-6 flex gap-x-6">
                //   <li>
                //     <a href={person.twitterUrl} className="text-gray-400 hover:text-gray-500">
                //       <span className="sr-only">Twitter</span>
                //       <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                //         <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                //       </svg>
                //     </a>
                //   </li>
                //   <li>
                //     <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                //       <span className="sr-only">LinkedIn</span>
                //       <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                //         <path
                //           fillRule="evenodd"
                //           d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                //           clipRule="evenodd"
                //         />
                //       </svg>
                //     </a>
                //   </li>
                // </ul>
