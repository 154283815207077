import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "How long do bees live?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "How many bees are there in a hive?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  // More questions...
]

export default function Faqs() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24">
		<div className="mx-auto max-w-4xl">
			<h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
			<p className="mt-2 text-base text-gray-600">
				Don't see the answer you are looking for? Feel free to {' '}
              <a href="mailto:benkittilsen@gmail.com" className="font-semibold text-green-900 hover:text-green-700">
				contact us
              </a>{' '}
              .
            </p>
			<div className="divide-y divide-gray-900/10">
				<div></div>
				<dl className="mt-6 space-y-6 divide-y divide-gray-900/10">
					{faqs.map((faq) => (
					  <Disclosure as="div" key={faq.question} className="pt-6">
						{({ open }) => (
						  <>
							<dt>
							  <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
								<span className="text-base font-semibold leading-7">{faq.question}</span>
								<span className="ml-6 flex h-7 items-center">
								  {open ? (
									<MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
								  ) : (
									<PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
								  )}
								</span>
							  </Disclosure.Button>
							</dt>
							<Disclosure.Panel as="dd" className="mt-2 pr-12">
							  <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
							</Disclosure.Panel>
						  </>
						)}
					  </Disclosure>
					))}
				</dl>
			</div>
		</div>
      </div>
	</div>
  )
}
