

// export default function Main(){
// 	return (
// 		<div className="">
// 			{/* Background Image with text */}
// 			<div className="relative">
// 				<img className="" src="background.jpg" alt="Background"/>
// 				{/* This makes the image darker*/}
// 				<div className="absolute top-0 right-0 h-full w-full bg-gray-500/30" />
// 				{/* Text over image*/}
// 				<div className="absolute top-0 translate-y-1/2 text-center w-full">
// 					<div className="font-bold py-6 text-white text-5xl invisible lg:visible">Kittilsen's Wildflower Honey</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// }

export default function Main() {
  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden lg:pt-14">
        <div className="mx-auto max-w-7xl px-6 py-24 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
				Kittilsen's Honey
            </h1>
            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p className="text-lg leading-8 text-gray-600">
                Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
                fugiat veniam occaecat fugiat aliqua. Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui
                lorem cupidatat commodo.
              </p>
              <div className="m-10 flex items-center gap-x-6">
              </div>
            </div>
            <img
              src="big_logo.jpg"
              alt=""
              className="w-full max-w-lg rounded-2xl object-cover lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
            />
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24" />
      </div>
       <div className="mt-32 mx-auto max-w-7xl px-8">
          <img
            src="background.jpg"
            alt=""
            className="w-full object-cover rounded-3xl outline outline-8 outline-green-900"
          />
        </div>
    </div>
  )
}
