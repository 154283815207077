

const locations = [
	{
		town: 'Masstown',
		locations: [
			{
				name: 'Masstown Market',
				link: 'https://www.google.com/maps/place/Masstown+Market/@45.3931177,-63.4988439,17z/data=!3m1!4b1!4m6!3m5!1s0x4b5937fc793a06fb:0xc4fe4b1e5ac60045!8m2!3d45.3931177!4d-63.496269!16s%2Fg%2F1thkr8rk?entry=ttu',
				address: ''
			}
		]
	},
	{
		town: 'Truro',
		locations: [
			{
				name: 'Sobeys (Robie Street)',
				address: ''
			},
			{
				name: 'Sobeys (Walker Street)',
				address: ''
			}
		]
	},
	{
		town: 'Bible Hill',
		locations: [
			{
				name: 'Foodland',
				address: ''
			}
		]
	},
	{
		town: 'Stewiacke',
		locations: [
			{
				name: 'Foodland',
				address: ''
			}
		]
	},
	{
		town: 'Elmsdale',
		locations: [
			{
				name: 'Withrows Fam Market',
				address: ''
			}
		]
	},
	{
		town: 'Halifax',
		locations: [
			{
				name: 'Local Source',
				address: ''
			}
		]
	},
	{
		town: 'New Glasgow Area',
		locations: [
			{
				name: 'Sobeys (Westside)',
				address: ''
			},
			{
				name: 'Sobeys (Aberdeen)',
				address: ''
			},
			{
				name: 'Sobeys (Stellerton)',
				address: ''
			},
			{
				name: 'Sobeys (Pictou)',
				address: ''
			},
		]
	},
	{
		town: 'Antigonish',
		locations: [
			{
				name: 'Sobeys',
				address: ''
			},
		]
	},
	{
		town: 'Port Hawksbury',
		locations: [
			{
				name: 'Sobeys',
				address: ''
			}
		]
	}

];

export default function Locations(){
	return (
		<div className="bg-white py-24 sm:py-32">
		  <div className="mx-auto max-w-7xl px-6 lg:px-8">
			<div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
			  <div className="grid grid-cols-1 gap-x-8 gap-y-10 pt-16 lg:grid-cols-3">
				<div>
				  <h2 className="text-3xl font-bold tracking-tight text-gray-900">Locations</h2>
				  <p className="mt-4 leading-7 text-gray-600">
					Our honey is available at the following locations across Central and Northern Nova Scotia
				  </p>
				</div>
				<div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
					{ locations.map(location =>(
					  <div className="rounded-2xl bg-gray-200 p-10 outline outline-yellow-300">
						<h3 className="text-base font-semibold leading-7 text-gray-900">{location.town}</h3>
						<address className="mt-3 space-y-1 text-sm not-italic leading-6 text-gray-600">
						  {location.locations.map(store => (
							<p>{store.name}</p>
						  ))}
						</address>
					  </div>
					))}
				</div>
			  </div>
			</div>
		  </div>
		</div>
  )
}
